// Generated by Framer (abcfa95)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, RichText, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["dkxRZH5nQ"];

const serializationHash = "framer-V5Xsr"

const variantClassNames = {dkxRZH5nQ: "framer-v-1n5w9v0"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({click, height, id, width, ...props}) => { return {...props, J1YV8GyL4: click ?? props.J1YV8GyL4} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;click?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, J1YV8GyL4, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "dkxRZH5nQ", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTap1176p3t = activeVariantCallback(async (...args) => {
if (J1YV8GyL4) {const res = await J1YV8GyL4(...args);
if (res === false) return false;}
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1n5w9v0", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"dkxRZH5nQ"} ref={ref ?? ref1} style={{...style}}><motion.div className={"framer-1m5j80f"} data-framer-name={"Question"} data-highlight layoutDependency={layoutDependency} layoutId={"dkxRZH5nQHdhCRKbQh"} onTap={onTap1176p3t}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "Q1VTVE9NO1NGIFBybyBEaXNwbGF5IFNlbWlib2xk", "--framer-font-family": "\"SF Pro Display Semibold\", \"SF Pro Display Semibold Placeholder\", \"-apple-system\", \"BlinkMacSystemFont\", sans-serif", "--framer-font-size": "20px", "--framer-letter-spacing": "0px", "--framer-line-height": "1.5em", "--framer-text-alignment": "left", "--framer-text-color": "var(--extracted-r6o4lv, var(--token-5fb841b3-a007-48ed-a9c7-669e35dd5762, rgb(92, 133, 168)))"}}>I use Google Calendar! What’s different about Structured?</motion.p></React.Fragment>} className={"framer-153z9tf"} fonts={["CUSTOM;SF Pro Display Semibold"]} layoutDependency={layoutDependency} layoutId={"dkxRZH5nQHYVH90OK3"} style={{"--extracted-r6o4lv": "var(--token-5fb841b3-a007-48ed-a9c7-669e35dd5762, rgb(92, 133, 168))", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline"}} verticalAlignment={"top"} withExternalLayout/><Image background={{alt: "", fit: "fit", intrinsicHeight: 29, intrinsicWidth: 50, pixelHeight: 29, pixelWidth: 50, positionX: "center", positionY: "center", src: "https://framerusercontent.com/images/JySSssUOKV4DGF1Yll9h99w4l8.svg"}} className={"framer-1devopp"} data-framer-name={"Plus"} layoutDependency={layoutDependency} layoutId={"dkxRZH5nQmS0TUAgWk"}/></motion.div></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-V5Xsr.framer-2b8vjg, .framer-V5Xsr .framer-2b8vjg { display: block; }", ".framer-V5Xsr.framer-1n5w9v0 { align-content: flex-start; align-items: flex-start; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 0px; height: min-content; justify-content: flex-start; overflow: hidden; padding: 0px; position: relative; width: 670px; }", ".framer-V5Xsr .framer-1m5j80f { -webkit-user-select: none; align-content: center; align-items: center; cursor: pointer; display: flex; flex: none; flex-direction: row; flex-wrap: nowrap; gap: 0px; height: min-content; justify-content: flex-start; overflow: visible; padding: 20px 40px 20px 0px; position: relative; user-select: none; width: 100%; }", ".framer-V5Xsr .framer-153z9tf { flex: 1 0 0px; height: auto; position: relative; white-space: pre-wrap; width: 1px; word-break: break-word; word-wrap: break-word; }", ".framer-V5Xsr .framer-1devopp { flex: none; height: 11px; overflow: hidden; position: relative; width: 16px; z-index: 1; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-V5Xsr.framer-1n5w9v0, .framer-V5Xsr .framer-1m5j80f { gap: 0px; } .framer-V5Xsr.framer-1n5w9v0 > * { margin: 0px; margin-bottom: calc(0px / 2); margin-top: calc(0px / 2); } .framer-V5Xsr.framer-1n5w9v0 > :first-child { margin-top: 0px; } .framer-V5Xsr.framer-1n5w9v0 > :last-child { margin-bottom: 0px; } .framer-V5Xsr .framer-1m5j80f > * { margin: 0px; margin-left: calc(0px / 2); margin-right: calc(0px / 2); } .framer-V5Xsr .framer-1m5j80f > :first-child { margin-left: 0px; } .framer-V5Xsr .framer-1m5j80f > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 70
 * @framerIntrinsicWidth 670
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]}}}
 * @framerVariables {"J1YV8GyL4":"click"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerNrkAEbSlB: React.ComponentType<Props> = withCSS(Component, css, "framer-V5Xsr") as typeof Component;
export default FramerNrkAEbSlB;

FramerNrkAEbSlB.displayName = "Row 2";

FramerNrkAEbSlB.defaultProps = {height: 70, width: 670};

addPropertyControls(FramerNrkAEbSlB, {J1YV8GyL4: {title: "Click", type: ControlType.EventHandler}} as any)

addFonts(FramerNrkAEbSlB, [{explicitInter: true, fonts: [{family: "SF Pro Display Semibold", source: "custom", url: "https://framerusercontent.com/assets/VswZaeGSdJGrUGNaDRbUgnu85jw.otf"}]}], {supportsExplicitInterCodegen: true})